import React from 'react'
import './FooterBottom.css'

function FooterBottom() {
  return (
    <div className='footer'>
       <h2 className='title'>Copyright &copy; Esteemed. All Rights Reserved.</h2>
    </div>
  )
}

export default FooterBottom
